
export function formDataToObject(formData: FormData) {
  const obj = {} as any;
  formData.forEach((value, key) => {
    if (obj[key]) {
      // Se a chave já existir, converte para array ou adiciona ao array existente
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  });
  return obj;
}
