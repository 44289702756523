import { AxiosRequestConfig } from "axios";
import { BaseResponse } from "../api/common/base-response";
import axiosFastApiControl from "../axiosFastApiControl";
import ToastMessagesService from "./toastMessages.service";

export interface ExtraOptions{
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
}


export class ApiRequestService {
  public static async get<T = any>(route: string, requestOptions?: Partial<AxiosRequestConfig>, extraOptions?: Partial<ExtraOptions>): Promise<BaseResponse<T>>{ 
    try {
      const response = await axiosFastApiControl.get<any, BaseResponse<T>, any>(route, requestOptions);
      this.showApiMessageResponse(response, extraOptions);
      
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async post<T = any>(route: string, body: any, requestOptions?: Partial<AxiosRequestConfig>, extraOptions?: Partial<ExtraOptions>): Promise<BaseResponse<T>>{ 
    try {
      const response = await axiosFastApiControl.post<any, BaseResponse<T>, any>(route, body, requestOptions);
      this.showApiMessageResponse(response, extraOptions);
      
      return response;
    } catch (error) {
      throw error;
    }
  }
  
  public static async patch<T = any>(route: string, body: any, requestOptions?: Partial<AxiosRequestConfig>, extraOptions?: Partial<ExtraOptions>): Promise<BaseResponse<T>>{    
    try {
      const response = await axiosFastApiControl.patch<any, BaseResponse<T>, any>(route, body, requestOptions);
      this.showApiMessageResponse(response, extraOptions);

      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async put<T = any>(route: string, body: any, requestOptions?: Partial<AxiosRequestConfig>, extraOptions?: Partial<ExtraOptions>): Promise<BaseResponse<T>>{    
    try {
      const response = await axiosFastApiControl.put<any, BaseResponse<T>, any>(route, body, requestOptions);
      this.showApiMessageResponse(response, extraOptions);
      
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async delete<T = any>(route: string, requestOptions?: Partial<AxiosRequestConfig>, extraOptions?: Partial<ExtraOptions>): Promise<BaseResponse<T>>{    
    try {
      const response = await axiosFastApiControl.delete<any, BaseResponse<T>, any>(route, requestOptions);
      this.showApiMessageResponse(response, extraOptions);
      
      return response;
    } catch (error) {
      throw error;
    }
  }




  private static showApiMessageResponse(response: BaseResponse, options?: Partial<ExtraOptions>){
    if(!options) return;

    if (response.statusCode >= 400 && options.showErrorMessage) ToastMessagesService.addMessage({message: response.message ?? "Ocorreu um erro na solicitação", category: "error"});
    else if(options.showSuccessMessage) ToastMessagesService.addMessage({message: response.message ?? "Operação realizada com sucesso", category: "success"});
  }
}

