import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { getLicense, LicenseResponse, removeLicense, setLicense as setUserLicense } from "../../api/license";
import { useAuth } from "../../hooks/useAuth";
import { useToastMessages } from "../../hooks/useToastMessages";

interface LicenseModalProps {
  show: boolean;
  handleModalHide: () => void;
}


const LicenseModal: React.FC<LicenseModalProps> = ({show, handleModalHide}) => {
  const {user} = useAuth();
  const {addMessage} = useToastMessages();
  const [loading, setLoading] = useState<boolean>(true);
  const [licenseData, setLicenseData] = useState<LicenseResponse|undefined>(undefined);


  useEffect(() => {
    if(show){
      setLoading(true);
      getLicense()
        .then(setLicenseData)
        .catch(()=>{
          addMessage({ 
            category: "error",
            title: "Erro ao buscar licença",
            message: `Ocorreu um erro ao tentar localizar as informções da licença`
          });
          handleModalHide();
        }).finally(()=>setLoading(false));
    }
  }, [show]);


  const onSetLicense = async (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    formData.append("user_id", user!.user_id);
    try {
      const response = await setUserLicense(formData);
      if(response.success){
        addMessage({ category: "success", message: "Licença vinculada com sucesso" });
        form.reset();
        handleModalHide();
      }else{
        addMessage({ category: "error", title: "Erro ao vincular a licença", message: `${response.error}` });
      }
    } catch (error) {
      addMessage({ category: "error", message: `Ocorreu um erro ao tentar vincular a licença` });
    }
  };
  
  const handleRemoveLicense = async () => {
    try {
      const response = await removeLicense(user!.user_id, licenseData!.licenseId!);
      if(response.success){
        addMessage({ category: "success", message: "Licença desvinculada com sucesso" });
        handleModalHide();
      }
      else addMessage({ category: "error", title: 'Erro ao desvincular licença', message: `${response.error}` });
    } catch (error) {
      addMessage({ category: "error", message: `Ocorreu um erro ao tentar desvincular a licença` });
    }
  }

  return (
    <Modal show={show} onHide={handleModalHide} onBackdropClick={handleModalHide}>
      <Modal.Header closeButton>
        <Modal.Title>Gerenciar Licença</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        )}
        {
          !loading && licenseData?.error && (
            <div>
              <p>Nenhuma licença associada.</p>
              <Form onSubmit={onSetLicense}>
                <Form.Group className="mb-3" controlId="license_id">
                  <Form.Label>ID da Licença:</Form.Label>
                  <Form.Control type="text" name="license_id" required/>
                </Form.Group>
                <Button type="submit">Associar Licença</Button>
              </Form>
            </div>
        )}
        {
          !loading && licenseData?.licenseId && licenseData.expirationDate && (
            <div>
              <p>Licença Associada: <strong>{licenseData.licenseId}</strong></p>
              <p>Data de Expiração: 
                <strong>
                  {new Date(licenseData.expirationDate).toLocaleDateString(undefined, { day: '2-digit',month:'2-digit', year: 'numeric' })}
                  </strong>
                </p>
                <Button variant="danger" onClick={handleRemoveLicense}>Desassociar Licença</Button>
            </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LicenseModal;
