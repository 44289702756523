import { Message } from "../hooks/useToastMessages";

class ToastMessagesService {
    private static addMessageFn: ((msg: Message) => void) | null = null;
  
    public static registerAddMessageFn(fn: (msg: Message) => void) {
      ToastMessagesService.addMessageFn = fn;
    }
  
    public static addMessage(msg: Message) {
        if (ToastMessagesService.addMessageFn) ToastMessagesService.addMessageFn(msg);
        else console.warn('ToastMessagesService: Função de adicionar mensagem não foi registrada.');
    }
  }
  
  export default ToastMessagesService;
