import { ApiRequestService } from "../services/apiRequest.service";
import { formDataToObject } from "./common/formData.utils";


export const changePassword = async (
  data: FormData
): Promise<any> => {
  const response = await ApiRequestService.post<void>("/change_password", formDataToObject(data));
  if (response.statusCode >= 400) return { error: response.message };
  return { success: true };
};
