import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import ChangePassword from "../pages/change-password/ChangePassword";
import ProtectedRoute from "../components/protected-route/ProtectedRoute";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));

const Estoque = lazy(() => import("../pages/estoque/Estoque"));
const Financeiro = lazy(() => import("../pages/financeiro/Financeiro"));
const Servicos = lazy(() => import("../pages/servicos/Serviços"));
const Home = lazy(() => import("../pages/home/Home"));
const Login = lazy(() => import("../pages/login/Login"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const Signup = lazy(() => import("../pages/signup/Signup"));
const CreateLicense = lazy(
  () => import("../pages/create-license/CreateLicense")
);
const Clientes = lazy(() => import("../pages/Clientes/Clientes"));
const Agendamento = lazy(() => import("../pages/agendamento/AppointmentManagement"));
const Planejamento = lazy(() => import("../pages/planning/Planning"));
const GestorAgendamento = lazy(() => import("../pages/Schedule-Manager/ScheduleManager"));


interface ProtectedElementProps {
  element: React.ReactElement;
}

const ProtectedElement = ({ element }: ProtectedElementProps) => (
  <ProtectedRoute>{element}</ProtectedRoute>
);

const Loading = () => <div>Loading...</div>;

const ProtectedRouteElement = (Component: React.FC) => (
  <ProtectedRoute>{<Component />}</ProtectedRoute>  // Aqui fazemos a correção para renderizar o componente corretamente
);

const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={ProtectedRouteElement(Profile)} />
        <Route path="/dashboard" element={ProtectedRouteElement(Dashboard)} />
        <Route path="/financeiro" element={ProtectedRouteElement(Financeiro)} />
        <Route
          path="/createLicense"
          element={ProtectedRouteElement(CreateLicense)}
        />
        <Route path="/estoque" element={ProtectedRouteElement(Estoque)} />
        <Route path="/servicos" element={ProtectedRouteElement(Servicos)} />
        <Route path="/clientes" element={ProtectedRouteElement(Clientes)} />
        <Route path="/agendamento" element={ProtectedRouteElement(Agendamento)} />
        <Route path="/planejamento" element={ProtectedRouteElement(Planejamento)} />
        <Route path="/gestor-agendamento" element={ProtectedRouteElement(GestorAgendamento)} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
