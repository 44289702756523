import { ApiRequestService } from "../services/apiRequest.service";
import { formDataToObject } from "./common/formData.utils";

export const addLicense  = async (formData: FormData) => {
  const body = formDataToObject(formData);
  const response = await ApiRequestService.post("/create_license", body);
  if(response.statusCode >= 300) throw response.message; 
  return response;
};



export interface LicenseResponse {
  error?: string;
  licenseId?: string;
  expirationDate?: string;
}
export const getLicense  = async (): Promise<LicenseResponse> => {
  const response = await ApiRequestService.get(`/get_license`);
  
  return {
    error: response.statusCode >= 300 ? response.message : undefined, 
    licenseId: response.content?.license_id,
    expirationDate: response.content?.data_expiracao,
  };
};



export interface RemoveLicenseResponse {
  error?: string;
  success?: string;
}
export const removeLicense  = async (user_id:string, license_id:string): Promise<RemoveLicenseResponse> => {
  const response = await ApiRequestService.post(`/remove_license`, { license_id, user_id });
  return { success: response.statusCode >= 300 ? undefined : response.message,  error: response.statusCode >= 300 ? response.message : undefined };
};



export interface SetUserLicenseResponse extends RemoveLicenseResponse{}
export const setLicense  = async (formData: FormData): Promise<SetUserLicenseResponse> => {
  const body = formDataToObject(formData);
  const response = await ApiRequestService.post("/set_license", body);
  return { success: response.statusCode >= 300 ? undefined : response.message,  error: response.statusCode >= 300 ? response.message : undefined };
};
