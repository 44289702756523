import React, { useEffect, useMemo } from "react";
import AppRoutes from "./routes/Routes";
import Navbar from "./components/navbar/Navbar";
import GlobalStyles from "./GlobalStyles";
import { useAuth } from "./hooks/useAuth";
import styled from "styled-components";
import { Analytics } from "@vercel/analytics/react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingProvider } from "./hooks/LoadingContext";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const App: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Lista de páginas públicas (para navegação sem autenticação)
  const publicPages = useMemo(() => {
    return ["/login", "/signup", "/"];
  }, []);

  // Verifica se a página atual é pública
  const isPublicPage = useMemo(() => {
    return publicPages.includes(location.pathname);
  }, [location, publicPages]);

  useEffect(() => {
    // Se o usuário está autenticado e estiver em uma página pública, redirecionar para o dashboard
    if (user && isPublicPage) {
      navigate("/dashboard");
    }

    // Se o usuário não está autenticado e está em uma página privada, redirecionar para o login
    if (!user && !isPublicPage) {
      navigate("/login");
    }
  }, [user, isPublicPage, navigate]);

  return (
    <LoadingProvider>
      <AppWrapper>
        {user && <Navbar />}
        <GlobalStyles />
        <Analytics />
        <AppRoutes />
      </AppWrapper>
    </LoadingProvider>
  );  
};

export default App;
