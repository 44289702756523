import axios, { AxiosError } from "axios";

const axiosFastApiControl = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/api/", // Ajuste conforme necessário
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*'
  },
});


axiosFastApiControl.interceptors.request.use(
  (config) => {
    
    /*const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }*/
    
    const storedUser = window.localStorage.getItem("user");
    if (storedUser) {
      const userId = JSON.parse(storedUser).user_id;
      config.params = { ...config.params, user_id: userId };
    }

    return config; // Retorna a requisição modificada
  },
  (error) => {
    // Manipula o erro de requisição antes de enviar
    console.error('Erro na requisição:', error);
    return Promise.reject(error); // Retorna o erro
  }
);


axiosFastApiControl.interceptors.response.use(
  (response) => response.data, // Retorno da api
  (error: AxiosError) => {
    if (error.response) return error.response.data; //erro retornado pela api
      
    
    if (error.request) { // Erro sem resposta (problema de rede, timeout, etc.)
      console.error('Nenhuma resposta recebida:', error.request);
    } else { // Erro na configuração da requisição
      console.error('Erro ao configurar a requisição:', error.message);
    }

    return Promise.reject(error); // Retorna o erro para ser tratado localmente
  }
);

export default axiosFastApiControl;
